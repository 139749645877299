var scrollPos = 0;

let overviewFilters = {
    aside: jQueryCaw("#caw-container .aside--left"),
    content: jQueryCaw("#caw-container  .l-content"),
    overview: jQueryCaw("#caw-container .overview--grid"),

    init: function () {
        overviewFilters.filterTop();
        overviewFilters.filterAside();
        overviewFilters.checkSubItems();
        overviewFilters.asideButton();
        overviewFilters.disableItems();
        overviewFilters.stickyMobileFilters();
    },

    checkSubItems: function () {
        jQueryCaw("#caw-container .filters--top .filter-checkbox").each(
            function () {
                let subChecked = jQueryCaw(this)
                    .children()
                    .find("input")
                    .is(":checked");

                if (subChecked) {
                    jQueryCaw(this)
                        .children(".filter__type.filter-button")
                        .addClass("filter__type--checked");
                } else {
                    jQueryCaw(this)
                        .children(".filter__type.filter-button")
                        .removeClass("filter__type--checked");
                }
            }
        );

        jQueryCaw("#caw-container .filters--top .filter-select").each(function () {
            let minSelected = jQueryCaw(this)
                .children()
                .find(".select-min option:first-child")
                .is(":selected");
            let maxSelected = jQueryCaw(this)
                .children()
                .find(".select-max option:first-child")
                .is(":selected");

            if (!minSelected || !maxSelected) {
                jQueryCaw(this)
                    .children(".filter__type.filter-button")
                    .addClass("filter__type--checked");
            } else {
                jQueryCaw(this)
                    .children(".filter__type.filter-button")
                    .removeClass("filter__type--checked");
            }
        });
    },

    resetFilters: function () {

        // Reset checked models aswell first!
        jQueryCaw("input.filter-make").each(function () {
            jQueryCaw(this).attr('data-models', '');
            jQueryCaw(this).removeData('models');
        });

        jQueryCaw("#caw-container .filters--top .filter, .filters-aside").each(
            function () {
                jQueryCaw(this).children().find("input").prop("checked", false);
                jQueryCaw(this)
                    .children()
                    .find("option:first-child")
                    .prop("selected", true);
                jQueryCaw(".filter-keywords input").val("").change();
                jQueryCaw(".filter-make-model .filter-model-list").html("");

                overviewFilters.checkSubItems();
                overviewFilters.disableItems();
            }
        );
    },

    filterTop: function () {
        jQueryCaw(document).on("click", function (e) {
            var container = jQueryCaw("ul.filter__items");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                overviewFilters.closeTopFilters();
                overviewFilters.checkSubItems();
            }
        });

        jQueryCaw("#caw-container .filters--top .filter .filter-button").on(
            "click",
            function (e) {
                let isOpen = jQueryCaw(this).hasClass("filter__type--active");

                overviewFilters.closeTopFilters();
                overviewFilters.checkSubItems();

                if (!isOpen) {
                    jQueryCaw("#caw-container").addClass("filter--open");
                    jQueryCaw(this).parent(".filter").addClass("filter--open");
                    jQueryCaw(this)
                        .parent(".filter")
                        .parent(".filters--top")
                        .addClass("filter--open");
                    jQueryCaw(this).addClass("filter__type--active");
                    e.stopPropagation();
                }
            }
        );
    },

    filterAside: function () {
        jQueryCaw("#caw-container .filters-aside .filter .filter__type").click(
            function () {
                jQueryCaw(this).parent(".filter").toggleClass("filter--open");
                jQueryCaw(this).toggleClass("filter__type--active");
            }
        );
    },

    asideButton: function () {
        jQueryCaw("#caw-container .filters--top .open-aside .filter-button").click(
            function () {
                let isOpen = jQueryCaw(this).hasClass("filter__type--active");
                let liRef = jQueryCaw(this).parent(".open-aside");

                if (!isOpen) {
                    overviewFilters.closeTopFilters();

                    // Filter button
                    liRef.addClass("filter--open");
                    jQueryCaw(this).addClass("filter__type--active");

                    // Aside
                    overviewFilters.aside.addClass("aside--open");
                    overviewFilters.content.removeClass("col-lg-12").addClass("col-lg-9");
                } else {
                    // Filter button
                    liRef.removeClass("filter--open");
                    jQueryCaw(this).removeClass("filter__type--active");

                    // Aside
                    overviewFilters.aside.removeClass("aside--open");
                    overviewFilters.content.removeClass("col-lg-9").addClass("col-lg-12");
                }
            }
        );
    },

    closeTopFilters: function () {
        jQueryCaw("#caw-container .filters--top").removeClass("filter--open");
        jQueryCaw("#caw-container .filters--top .filter").removeClass(
            "filter--open"
        );
        jQueryCaw(
            "#caw-container .filters--top .filter .filter-button"
        ).removeClass("filter__type--active");
        jQueryCaw("#caw-container .overview--grid").parent().trigger("change");
        jQueryCaw("#caw-container").removeClass("filter--open");
    },

    disableItems: function () {
        jQueryCaw(".select-min option, .select-max option").removeAttr("disabled");

        jQueryCaw("#caw-container .filters--top .filter-select").each(function () {
            let minValue = parseInt(
                jQueryCaw(this).children().find(".select-min option:selected").val()
            );
            let maxValue = parseInt(
                jQueryCaw(this).children().find(".select-max option:selected").val()
            );
            let typeFilter = jQueryCaw(this)
                .children()
                .find(".select-max")
                .attr("class")
                .split(" ")[1]
                .slice(0, -4);

            let minSelected = jQueryCaw(this)
                .children()
                .find(".select-min option:first-child")
                .is(":selected");
            let maxSelected = jQueryCaw(this)
                .children()
                .find(".select-max option:first-child")
                .is(":selected");

            if (!minSelected && maxSelected) {
                jQueryCaw("." + typeFilter + "-max option")
                    .filter(function () {
                        return jQueryCaw(this).val() <= minValue;
                    })
                    .prop("disabled", false);
            }

            if (!maxSelected) {
                jQueryCaw("." + typeFilter + "-min option")
                    .filter(function () {
                        return jQueryCaw(this).val() > maxValue;
                    })
                    .prop("disabled", true);
            }

            if (!minSelected) {
                jQueryCaw("." + typeFilter + "-max option")
                    .filter(function () {
                        return jQueryCaw(this).val() < minValue;
                    })
                    .prop("disabled", true);
            }
        });
    },

    stickyMobileFilters() {
        let offset = (
                typeof stickyEl != 'undefined' &&
                (jQueryCaw(stickyEl).css('position') == 'fixed' || jQueryCaw(stickyEl).css('position') == 'sticky')
            ) ? jQueryCaw(stickyEl).outerHeight() : 0;

        let elem = jQueryCaw('.header .mobile-nav');
        let parent = jQueryCaw('#caw-container');
        let parentOffset = parent.offset();

        let scrollTop = jQueryCaw(window).scrollTop();
        let scrollMin = parentOffset.top - offset;
        let scrollMax = parent.outerHeight() + parentOffset.top + offset - elem.outerHeight();
        let scrollPos = offset;

    if (elem.is(':visible')) {
        if (!elem.hasClass('sticky')) {
            elem.addClass('sticky')
        }

        elem.parent().attr('style', '');
        elem.css({'max-width': parent.outerWidth() + 'px'});

        if (scrollTop > scrollMin && scrollTop < scrollMax) {
            elem.css({
                'position': 'fixed',
                'top': scrollPos + 'px',
                'bottom': '',
                'z-index': '101'
                });

            elem.parent().css({'padding-bottom': parseInt(elem.outerHeight()) + 'px'});
        } else if (scrollTop > scrollMin && scrollTop >= scrollMax) {
            elem.css({
                'position': 'absolute',
                'top': '',
                'bottom': '0px'
                });
        } else {
            elem.attr('style', '');
        }
    } else {
        elem.removeClass('sticky');
        elem.attr('style', '');
    }
    },

    stickyFilterBtn() {
        var parentElem = jQueryCaw("#caw-container");
        var targetElem = jQueryCaw(".btn.filters-aside__button");

        var scrollTop = jQueryCaw(this).scrollTop();
        var offsetParent = parentElem.offset();

        var scrollMax =
            offsetParent.top + parentElem.outerHeight(false) - window.innerHeight;

        if (scrollTop > scrollMax) {
            targetElem.css({bottom: scrollTop - scrollMax + "px"});
        } else {
            targetElem.css({bottom: ""});
        }

        targetElem.css({
            width: targetElem.closest(".main").innerWidth() - 30 + "px",
        });
    }

};

jQueryCaw(document).ready(function () {
    overviewFilters.init();

    jQueryCaw(".reset-filters, a.reset-filter").click(function (event) {
        overviewFilters.resetFilters();
        event.preventDefault();
    });

    jQueryCaw(".select-min, .select-max").on("change", function (event) {
        overviewFilters.disableItems();
        event.preventDefault();
    });

    jQueryCaw("#filter-make-visible-toggle").click(function (event) {
        jQueryCaw(".filter-make-model").toggleClass("fold-show-all");
        jQueryCaw(this)
            .parent()
            .parent()
            .find(".is-hidden, .is-visible")
            .toggleClass("is-hidden is-visible");
        event.preventDefault();
    });

    jQueryCaw(".mobile-nav .open-filter").click(function (event) {
        event.preventDefault();

        if (!jQueryCaw('#caw-container').hasClass('mobile--filter--open')) {
            scrollPos = jQueryCaw(window).scrollTop();
        }

        jQueryCaw("html, body").toggleClass("caw--mobile--filter--open");
        jQueryCaw("#caw-container").toggleClass("mobile--filter--open");
        jQueryCaw(window).trigger("scroll");
    });

    jQueryCaw(".mobile-nav .close-filter, .btn.filters-aside__button").click(function (event) {
        jQueryCaw("html, body").removeClass("caw--mobile--filter--open");
        jQueryCaw("#caw-container").removeClass("mobile--filter--open");
        jQueryCaw("#caw-container").removeClass("filter--open");

        event.preventDefault();
    });

    jQueryCaw('.mobile-nav > div > a').click(function (event) {
        if (!jQueryCaw('#caw-container').hasClass('mobile--filter--open')) {
            jQueryCaw(document).scrollTop(scrollPos);
        } else {
            jQueryCaw('html, body').scrollTop(jQueryCaw('#caw-container').offset().top - 100);
        }
    });
});

jQueryCaw(window).on("load", function () {
    jQueryCaw(window).trigger("scroll");
});

jQueryCaw(window).on("load resize", function () {
    var maxWidthLg = 960;
    var maxWidthXlg = 1300;
    var gridClassLg = "overview--col-xl-3";
    var gridClassXlg = "overview--col-xl-4";

    var parentElem = jQueryCaw("#caw-container .l-content");
    var targetElem = jQueryCaw("#caw-container .overview.overview--grid");

    var outerWidth = parentElem.outerWidth(false);

    targetElem.removeClass(gridClassLg).removeClass(gridClassXlg);

    if (outerWidth > maxWidthLg && outerWidth < maxWidthXlg) {
        targetElem.addClass(gridClassLg);
    } else if (outerWidth >= maxWidthXlg) {
        targetElem.addClass(gridClassXlg);
    }
});

jQueryCaw(window).on("resize", function (event) {
    event.stopPropagation();
    overviewFilters.stickyMobileFilters();
    overviewFilters.stickyFilterBtn();
});

jQueryCaw(window).scroll(function (event) {
    event.stopPropagation();
    overviewFilters.stickyMobileFilters();
    overviewFilters.stickyFilterBtn();
});

jQueryCaw("#caw-container .l-content").on("change", function () {
    jQueryCaw(window).trigger("resize");
});
