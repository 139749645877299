const jQueryCaw = jQuery.noConflict();

let detailGallery = {
    config: {
        slickElement: jQueryCaw('.caw-detail__gallery'),
        slickThumbs: jQueryCaw('.caw-detail__gallery__thumbs'),
        detailMain: jQueryCaw('.main.caw-detail'),
        closeButton: jQueryCaw('<button class="slick-close"></button>'),
        slickElementOptions: {
            centerMode: true,
            centerPadding: 0,
            mobileFirst: true,
            lazyload: 'progressive',
            slidesToShow: 1,
            slidesToScroll: 1,
            respondTo: 'min',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        centerPadding: '12%',
                    }
            },
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: '15%',
                    }
            },
            ],
            variableWidth: true,
        },
    },
    init: function () {
        // Init gallery.
        detailGallery.config.slickElement.slick(detailGallery.config.slickElementOptions);

        // Act on breakpoint.
        detailGallery.config.slickElement.on('breakpoint', function (event, slick, currentSlide, nextSlide) {
            detailGallery.setMaxWidth();
        });

        // Interaction.
        detailGallery.config.slickElement.click(function (ev) {
            if (!jQueryCaw(ev.target).is('button') && !jQueryCaw(this).hasClass('open')) {
                detailGallery.openGallery();
            }
        });

        // Photo button.
        jQueryCaw('.caw-detail__media .photo').click(function () {
            let currentSlide = detailGallery.config.slickElement.find('.slick-current.slick-active.slick-center');
            if (currentSlide) {
                if (currentSlide.find('iframe').length > 0 || currentSlide.find('video').length > 0 || currentSlide.find('embed').length > 0) {
                    // When the current slide contains a video or an iFrame, go to first slide (should be an image slide).
                    detailGallery.openGallery(0);
                    return;
                }
            }
            detailGallery.openGallery();
        });

        // Video button.
        jQueryCaw('.caw-detail__media .video').click(function () {
            // The offset here is just a really large number to make it skip to last item.
            detailGallery.openGallery(999999);
        });

        // 360 button.
        jQueryCaw('.caw-detail__media .media360.photoMotion').click(function () {
            // 360 items are always the first item in the gallery.
            detailGallery.openGallery(0);
        });

        // Close button.
        jQueryCaw('.caw-detail__header').prepend(detailGallery.config.closeButton);

        detailGallery.config.closeButton.click(function () {
            if (detailGallery.config.slickElement.hasClass('open')) {
                detailGallery.closeGallery();
            }
        });
    },
    openGallery: function (index) {
        // Set classes on parent elements.
        jQueryCaw('html, body').addClass('caw-gallery--open');
        jQueryCaw('#app-wrapper.caw, #caw-container.caw').addClass('gallery--open');

        // Set classes on slick related elements.
        detailGallery.config.slickElement.addClass('open');
        detailGallery.config.slickThumbs.addClass('show');
        detailGallery.config.closeButton.addClass('show');
        detailGallery.config.detailMain.addClass('hide');

        // Update max widths.
        detailGallery.setMaxWidth();

        // Set slick options for navigating through slides.
        detailGallery.config.slickElement.slick('slickSetOption', {
            'accessibility': true,
            'asNavFor': '.caw-detail__gallery__thumbs',
            'variableWidth': true,
            'responsive': [],
        }, true);

        // Init thumbnail gallery.
        let slidesToShow = Math.round(jQueryCaw(window).width() / 160);
        slidesToShow = slidesToShow % 2 === 0 ? slidesToShow - 1 : slidesToShow;

        detailGallery.config.slickThumbs.on('init reInit afterChange', function (event, slick) {
            detailGallery.config.slickThumbs.removeClass('slick-no-slide');
            if (slick.slideCount <= slick.options.slidesToShow) {
                detailGallery.config.slickThumbs.addClass('slick-no-slide');
            }
        });

        detailGallery.config.slickThumbs.slick({
            arrows: false,
            asNavFor: '.caw-detail__gallery',
            centerMode: slidesToShow % 2 === 0 ? false : true,
            centerPadding: jQueryCaw(window).width() < 390 ? '90px' : '40px',
            dots: false,
            focusOnSelect: true,
            mobileFirst: true,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
        });

        // Set correct active slide and thumbnail.
        if (index === undefined || index === null) {
            index = detailGallery.config.slickElement.slick('slickCurrentSlide');
        }

        detailGallery.config.slickThumbs.slick('slickGoTo', index);
    },
    closeGallery: function () {
        // Remove classes from parent elements.
        jQueryCaw('html, body').removeClass('caw-gallery--open');
        jQueryCaw('#app-wrapper.caw, #caw-container.caw').removeClass('gallery--open');

        // Remove classes from slick related elements.
        detailGallery.config.slickElement.removeClass('open');
        detailGallery.config.slickThumbs.removeClass('show');
        detailGallery.config.closeButton.removeClass('show');
        detailGallery.config.detailMain.removeClass('hide');

        // Set slick options for navigating through slides. Back to default.
        detailGallery.config.slickElement.slick('slickSetOption', detailGallery.config.slickElementOptions, true);

        // Back to the active slide.
        let index = detailGallery.config.slickElement.slick('slickCurrentSlide');
        detailGallery.config.slickElement.slick('slickGoTo', index);

        // Unslick thumb gallery.
        detailGallery.config.slickThumbs.slick('unslick');

        // Trigger resize event to prevent (sidebar) offset issues.
        jQueryCaw(window).trigger('resize');
    },
    setMaxWidth: function () {
        if (!detailGallery.config.slickElement.hasClass('open')) {
            let elem = detailGallery.config.slickElement;
            let maxWidth = elem.find('.slick-list').width();
            detailGallery.config.slickElement.find('img').css({'max-width': maxWidth + 'px'});
            detailGallery.config.slickElement.find('iframe').css({'min-width': maxWidth + 'px'});
        } else {
            detailGallery.config.slickElement.find('img, iframe').css({'max-width': '', 'min-width': ''});
        }
    }
};

let overviewGallery = {
    init: function () {
        if (jQueryCaw('#caw-container .caw-teaser__gallery').length) {
            overviewGallery = jQueryCaw("#caw-container .caw-teaser__gallery");
        } else if (jQueryCaw("#caw-container .teaser__image").length) {
            overviewGallery = jQueryCaw("#caw-container .teaser__image");
        }

        if (overviewGallery.length > 0) {
            overviewGallery.on('click', function (ev) {
                if (jQueryCaw(ev.target).is('img')) {
                    jQueryCaw(this).parent().find('.btn.btn--primary')[0].click();
                }
            });

            overviewGallery.slick({
                arrows: true,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
            });

            // Refresh slider on sidebar toggle.
            jQueryCaw(".open-aside .filter__type.filter-button").click(function (
                event
            ) {
                var galleryRefresh;
                jQueryCaw(".overview--grid").fadeOut(300, function () {
                    jQueryCaw(this).fadeIn(1000);

                    galleryRefresh = setTimeout(function () {
                        overviewGallery.slick("refresh");
                    }, 100);

                    jQueryCaw(".overview--grid").parent().change();
                });
            });
        }
    },
};

let mobileMenu = {
    init: function () {
        jQueryCaw(".caw-mobilemenu .more").click(function (event) {
            event.preventDefault();
            jQueryCaw(".caw-mobilemenu-sub").toggleClass("caw-mobilemenu-sub--open");
        });

        jQueryCaw(".caw-mobilemenu-sub .close").click(function () {
            jQueryCaw(this)
                .parent(".caw-mobilemenu-sub")
                .toggleClass("caw-mobilemenu-sub--open");
        });
    },
};

let foldOut = {
    init: function () {
        jQueryCaw(".caw-foldout--closed").children(".caw-foldout__content").hide();

        jQueryCaw(".caw-foldout__trigger").click(function () {
            jQueryCaw(this).next(".caw-foldout__content").slideToggle();
            jQueryCaw(this)
                .parent(".caw-foldout")
                .toggleClass(function () {
                    return jQueryCaw(this).is(".caw-foldout--open, .caw-foldout--closed")
                        ? "caw-foldout--open caw-foldout--closed"
                        : "caw-foldout--open";
                });
        });
    },
};

jQueryCaw(document).ready(function () {
    detailGallery.init();
    overviewGallery.init();
    mobileMenu.init();
    foldOut.init();

    jQueryCaw(".link-foldout").click(function () {
        jQueryCaw(this).next(".filter__items--showmore").slideToggle();
        jQueryCaw(this).toggleClass("link-foldout--closed");
    });

    jQueryCaw(window).resize(function () {
        detailGallery.setMaxWidth();

        if (detailGallery.config.slickThumbs.hasClass("slick-initialized")) {
            let slidesToShow = Math.round(jQueryCaw(window).width() / 160);
            slidesToShow = slidesToShow % 2 === 0 ? slidesToShow - 1 : slidesToShow;

            detailGallery.config.slickThumbs.slick(
                "slickSetOption",
                "slidesToShow",
                slidesToShow
            );
            detailGallery.config.slickThumbs.slick(
                "slickSetOption",
                "centerMode",
                slidesToShow % 2 === 0 ? false : true
            );
            detailGallery.config.slickThumbs.slick(
                "slickSetOption",
                "centerPadding",
                jQueryCaw(window).width() < 380 ? '90px' : '40px'
            );

            detailGallery.config.slickThumbs.slick("setPosition");
            detailGallery.config.slickThumbs[0].slick.refresh();
        }
    });
});

jQueryCaw(document).on("keyup", function (evt) {
    if (evt.keyCode === 27) {
        if (detailGallery.config.slickElement.hasClass("open")) {
            detailGallery.closeGallery();
        }
    }
});

// Detect touch device.
function isTouch()
{
    var isTouch = "ontouchstart" in document.documentElement;
    return isTouch;
}

// Detect mobile device.
// As seen on: http://stackoverflow.com/a/22327971.
function isMobile()
{
    var a = navigator.userAgent || navigator.vendor || window.opera;
    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
        )
    );
}

// Detect mobile device.
// As seen on: http://www.abeautifulsite.net/detecting-mobile-devices-with-javascript.
var mobileDetection = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (
            mobileDetection.Android() ||
            mobileDetection.BlackBerry() ||
            mobileDetection.iOS() ||
            mobileDetection.Opera() ||
            mobileDetection.Windows()
        );
    },
};

// Detect mobile device / browser.
jQueryCaw(window)
    .on("resize", function () {
        jQueryCaw("html").addClass("is-caw");

        // Detect touch device.
        if (isTouch()) {
            jQueryCaw("html").addClass("is-touch");
        } else {
            jQueryCaw("html").removeClass("is-touch");
        }

        // Detect mobile device.
        if (mobileDetection.any()) {
            jQueryCaw("html").addClass("is-mobile");
        } else {
            jQueryCaw("html").removeClass("is-mobile");
        }

        // Detect iOS.
        if (mobileDetection.iOS()) {
            jQueryCaw("html").addClass("is-ios");
        } else {
            jQueryCaw("html").removeClass("is-ios");
        }

        // Detect Safari browser.
        if (!!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)) {
            jQueryCaw("html").addClass("is-safari");
        } else {
            jQueryCaw("html").removeClass("is-safari");
        }

        // Detect Chrome browser.
        if (!!window.chrome) {
            jQueryCaw("html").addClass("is-chrome");
        } else {
            jQueryCaw("html").removeClass("is-chrome");
        }
    })
    .resize();
